// used for the main content div and the header's H1
$left-content-padding: 2.5rem;

body:not(.home) app-layout-site {
  .site-sidebar {
    left: 0;

    @include media-breakpoint-only(xs) {
      position: relative;
      display: block;
      width: initial;
      background-image: none;
    }
  }

  main {
    margin-left: 280px;
    transition: margin-left 0.3s;

    @include media-breakpoint-only(xs) {
      margin-left: 0 !important;
    }
  }

  .main-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }
}

body.home {
  main {
    margin-left: 0;
    transition: margin-left 0.3s;
  }
}

body.home.sidebar-open main {
  margin-left: 280px;
  transition: margin-left 0.3s;
}

body.home.sidebar-open .site-sidebar {
  left: 0;
  transition: left 0.3s;
}

// the main content section on every page on the site
.scrollspy-content {
  padding-bottom: 5rem;

  @include media-breakpoint-up(sm) {
    padding-left: 2.5rem !important;
  }

  section {
    margin-top: 2rem;
    border-top: 1px solid var(--divider_color);

    &:not(:first-of-type) {
      margin-top: 3rem;
    }

    h1 {
      .badge {
        display: inline-block;
        transform: translateY(-3px);
      }

      + h2 {
        margin-top: 0;
      }
    }

    .tab-content-noborder {
      .tab-pane {
        border-bottom: none;
      }
    }
  }
}

// DS site's special badges
@mixin doc-badges {
  display: inline-block;
  margin-left: 0.5rem;
  transform: translateY(-2px);
}

.badge-added {
  @include doc-badges;
  background-color: var(--green);
}

.badge-deprecated {
  @include doc-badges;
  background-color: var(--orange);
}

.badge-removed {
  @include doc-badges;
  background-color: var(--danger);
}

.badge-notaccessible {
  @include doc-badges;
  background-color: #717171;
}

.badge-usertested {
  @include doc-badges;
  background-color: var(--mediumblue);
}
