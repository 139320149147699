@keyframes grow {
  from {
    transform: scale(1);
  }

  to {
    animation-play-state: paused;
    transform: scale(1.05);
  }
}

.arrow-labels {
  z-index: 10;
  padding: 3px 6px;
  background-color: #fff;
  font-family: arial, sans-serif;
  font-size: 12px;
  font-weight: bold;
}

.drag-item {
  padding: 10px 10px 10px 18px;
  margin-bottom: 3px;
  border: 1px solid var(--rich200);
  // transition: all .2s;
  background-image: url($gripper);
  background-position: 3px center;
  background-repeat: no-repeat;

  &:hover {
    background-color: var(--blue50);
    // cursor: pointer;
    cursor: grab;
  }

  &:active {
    background-color: var(--rich200);
    cursor: grab;
  }

  //the drop container
  &.gu-transit {
    border: 3px dashed var(--rich200);
    background-color: var(--blue50);
    opacity: 1;
  }
}

.drag-container {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.drop-box {
  padding: 50px 10px;
  margin-bottom: 20px;
  border: 3px dashed #ddd;
  background: var(--rich100);
  text-align: center;
  transition: border-color 0.2s linear;

  &.dragover {
    border-color: var(--hyperlink);
  }

  *.dragover-err {
    border-color: var(--danger);
  }
}

.button {
  width: 70px;

  /* Safari and Chrome */
  padding: 10px;
  margin: 10px;
  -moz-appearance: button;

  /* Firefox */
  -webkit-appearance: button;
}

.my-drop-zone {
  min-height: 1.5rem;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e3e3e3;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.nv-file-over {
  border: dotted 3px var(--danger);
}

/* Default class applied to drop zones on over */
.another-file-over-class {
  border: dotted 3px var(--successdark);
}

.ui-fileupload {
  &.ui-widget {
    background-color: transparent;

    .ui-fileupload-content {
      border-color: var(--border_color);
      background-color: var(--white);

      .bgwhite & {
        background-color: var(--rich100);
      }

      .ui-messages.ui-messages-error {
        padding: 1rem 1rem 1rem 3rem;
        background-position: 1rem center;
        background-repeat: no-repeat;
        background-size: 1.5rem 1.5rem;
        border-radius: 0;
        border-color: #d30c0c;
        background-color: #fdf2f2;
        background-image: url('data:image/svg+xml,%3Csvg%20height%3D%2226%22%20width%3D%2226%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20transform%3D%22rotate(180%2012.5%2012.5)%22%3E%3Ccircle%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%20stroke%3D%22%23DE0F0E%22%20stroke-width%3D%222%22%2F%3E%3Cg%20transform%3D%22translate(11%205)%22%3E%3Ccircle%20cx%3D%221%22%20cy%3D%221%22%20fill%3D%22%23DE0F0E%22%20r%3D%221%22%2F%3E%3Cpath%20d%3D%22M1%205v8%22%20stroke%3D%22%23DE0F0E%22%20stroke-linecap%3D%22round%22%20stroke-width%3D%222%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
        border-radius: 2px;
        color: var(--text);

        ul {
          list-style: none;
        }
      }
    }
  }
}

.ui-fileupload-buttonbar.ui-widget-header {
  border: 0;

  p-button {
    cursor: pointer;
    background-color: transparent;

    button.ui-state-disabled {
      border-color: var(--disabled);
      color: var(--disabled);
      pointer-events: none;
    }
  }
}

// Material CDK drag drop

.dragdrop-list {
  border: solid 1px var(--border_color);
  min-height: 60px;
  background: white;
  border-radius: 4px;
  overflow: hidden;
  display: block;
}

.dragdrop-item {
  padding: 20px 10px 20px 24px;
  border-bottom: solid 1px var(--border_color);
  color: var(--text);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
  background-image: url($gripper);
  background-position: 6px center;
  background-repeat: no-repeat;

  .bgdark & {
    background-color: var(--dark_input_bg);
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.example-box:last-child {
  border: none;
}

.example-list.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.action-list-panel {
  .dragdrop-item {
    padding: 10px 10px 10px 24px;
  }
}
