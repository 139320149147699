.nav-tabs,
ul[role='tablist'].nav {
  border-bottom: 1px solid var(--divider_color);
  // margin-bottom: -1px;

  a,
  a.nav-link {
    padding: 8px 16px;
    margin-right: 2px;
    border-bottom-color: transparent !important;
    background-color: var(--bg_rich);
    border-radius: 0;
    color: var(--text_color);
    font-family: var(--font_family_brand_regular);
    text-decoration: none;
    // &:not(.disabled):focus-visible,&:not(.disabled):focus{
    //   @include focused;
    //   background-color:var(--rich100);
    // }
    &.disabled {
      color: var(--rich400);
      cursor: default;
      opacity: 0.6;
    }

    &:not(.active):hover,
    &:not(.active):not(.disabled):hover {
      border-color: var(--bg_rich_color) var(--bg_rich_color) transparent;
      color: var(--secondary);

      .bgdark & {
        color: var(--hyperlink);
      }
    }

    &:not(.active):not(.disabled):focus,
    &:not(.active):focus,
    &:not(.active):not(.disabled):focus-visible,
    &:not(.active):focus-visible {
      @include focused;
      background-color: var(--rich100);
    }
    &.disabled:focus,
    &:focus-visible {
      outline: none;
    }

    &.active {
      border: 1px solid var(--divider_color);
      border-top: 2px solid var(--hyperlink);
      border-bottom-color: transparent !important;
      background-color: var(--bg_rich_color);
      color: var(--secondary);
      cursor: default;

      .bgwhite & {
        background-color: #fff;
      }

      .bgdark & {
        color: var(--hyperlink);
        background-color: var(--dark_bg);
        border-bottom-color: var(--dark_bg) !important;

        &:hover {
          border-top: 2px solid var(--hyperlink);
        }
        &:focus-visible,
        &:focus {
          @include focused;
        }
      }

      &:hover {
        border: 1px solid var(--divider_color);
        border-top: 2px solid var(--secondary);
        border-bottom-color: transparent !important;
      }
      &:focus-visible,
      &:focus {
        @include focused;
      }
      .card & {
        background-color: #fff;
      }
    }
  }

  @media (forced-colors: active) {
    a,
    a.nav-link,
    a.nav-link.disabled {
      &.active {
        border-top: 6px solid #ff0;
        padding: 4px 16px 8px;

        &.hover {
          border-top: 6px solid #ff0;
          padding: 4px 16px 8px;
        }
      }
    }
  }
}

// for BS4 tabs
.tab-content {
  .tab-pane {
    padding: 1rem;
    background-color: transparent;
    border-bottom: 1px solid var(--divider_color);
  }

  &.tab-content-bordered {
    border: 1px solid var(--border_color);
    border-top: 0;
  }
}

//for ngb tabs
.tabset-bordered:not(.tabset-codeblock) {
  .tab-content {
    border: 1px solid var(--border_color);
    border-top: 0;
  }
}

ul[role='tablist'].nav {
  li {
    margin-bottom: -1px;
  }
}
