.ui-inputtext,
.p-inputtext {
  width: 100%;
  padding: $input-btn-padding-y $input-btn-padding-x;
  border: 1px solid var(--input_border_color);
  background-color: var(--white);
  border-radius: $border-radius;
  color: var(--text);
  font-family: var(--font_family_base);
  font-size: var(--font_size_base);
  font-weight: normal;
  line-height: var(--line_height_base);

  .bgwhite & {
    background-color: var(--form_control_bg_richcolor);
  }

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--dark_fg);
    border: 1px solid var(--dark_input_border);
  }
}

.ui-multiselect-trigger-icon.pi.pi-caret-down {
  font-family: 'pbi-icon-mini' !important;
  font-size: 16px;

  &::before {
    content: '\ea33' !important;
  }
}

.ui-multiselect {
  .ui-multiselect-trigger {
    top: 8px !important;
    right: 7px !important;
  }
}
