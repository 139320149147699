footer {
  padding: 1rem 0;
  background-color: var(--border_color);
  color: var(--text);
  font-size: var(--font_size_sm);
  // taken from bootstrap's "fixed-bottom" class
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  .bgdark & {
    background-color: var(--dark_bg_accent);
  }

  @include media-breakpoint-only(xs) {
    position: relative;
  }

  .footer-logo-copyright {
    display: flex;
    flex-direction: row;
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
  }

  .footer-logo {
    display: block;
    margin-right: 0.25rem;

    &::before {
      display: inline-block;
      vertical-align: middle;
      width: 132px;
      height: 27px;
      content: url($pb-logo-sm-gradient);
    }
  }

  ul.list-inline {
    margin: 0;

    li {
      margin: 0;

      &:not(:first-child) {
        margin-left: 0.5rem;
      }
    }
  }
}

.bgdark footer .footer-logo::before {
  content: url($pb-logo-sm-ko);
}
