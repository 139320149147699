@mixin gradient-over-image($image) {
  background-image: linear-gradient(135deg, var(--grad_start) 0%, var(--grad_end) 50%, var(--grad_end) 100%),
    linear-gradient(#bbb, #bbb), url($image);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
}

.welcome-bgimg {
  @include gradient-over-image('/assets/images/welcome/bg-imageDemo.jpg');
  height: 100vh;
}
