.toast-close-button {
  color: var(--text) !important;
  opacity: 0.8;
  font-size: 26px;
  font-weight: normal;

  &:hover,
  &:focus {
    opacity: 1;
  }
}

@mixin make-toast($bg, $bdr, $icon) {
  background-color: $bg;
  background-image: url($icon) !important;
  border-color: $bdr;
  border-radius: $border-radius;
  color: var(--text);
  background-position: 0.8rem 0.8rem;

  i {
    color: $bdr;
  }
}

// OVERWRITE DEFAULT TOASTR VALUES

.toast-container {
  .ngx-toastr {
    width: 320px;
    padding: 1em 1.5em 1em 3.5em !important;
    border-radius: $border-radius;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.3);
    opacity: 1;
    &:hover {
      box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.45);
      cursor: pointer;
      opacity: 1;
    }

    > div {
      margin-right: 0.5em;
    }

    .toast-close-button {
      top: 0;
      color: var(--text);
      line-height: 17px;
      opacity: 0.8;
      transform: translateY(-2px);
      font-size: 26px;
      font-weight: normal;

      &:hover,
      &:focus {
        opacity: 0.9;
      }
    }

    .toast-message {
      font-size: var(--font_size_base);
      label {
        color: var(--hyperlink);
      }

      a {
        color: var(--hyperlink);
        &:hover {
          color: var(--hyperlink_hover);
        }
      }
    }

    .toast-title {
      @extend .toast-message;
    }
  }

  .ngx-toastr {
    &.toast-warning {
      @include make-toast($state-warning-bg, $state-warning-border, $icon-warning);
    }

    &.toast-error {
      @include make-toast($state-danger-bg, $state-danger-border, $icon-danger);
    }

    &.toast-success {
      @include make-toast($state-success-bg, $state-success-border, $icon-success);
    }

    &.toast-info {
      @include make-toast($state-info-bg, $state-info-border, $icon-info);
    }
  }
}
