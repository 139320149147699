@import '../../../../node_modules//primeng/resources/components/table/table';

.p-datatable,
.p-treetable {
  .p-datatable-table,
  .p-treetable-table {
    .p-datatable-thead,
    .p-treetable-thead {
      tr {
        th {
          vertical-align: bottom;
          border-top: 0;
          padding: $table-cell-padding;
          border-bottom: 1px solid var(--border_color);

          @include table-headers;

          &.p-sortable-column {
            color: var(--text);

            sortalticon {
              &:before {
                content: '\eaaa';
                font-family: 'pbi-icon-mini';
              }

              svg {
                display: none;
              }
            }

            sortamountupalticon {
              &:before {
                content: '\ea0c';
                font-family: 'pbi-icon-mini';
              }

              svg {
                display: none;
              }
            }

            sortamountdownicon {
              &:before {
                content: '\ea33';
                font-family: 'pbi-icon-mini';
              }

              svg {
                display: none;
              }
            }
          }

          &.p-resizable-column {
            border-right: 1px solid var(--border_color);
          }

          &.p-frozen-column {
            background-color: #fff;
          }
        }
      }
    }

    .p-datatable-tbody,
    .p-treetable-tbody {
      > tr {
        &:not(:first-of-type) {
          > td {
            border-top: 1px solid var(--border_color);
          }

          &:last-of-type {
            > td {
              border-bottom: 1px solid var(--border_color);
            }
          }
        }

        td {
          padding: $table-cell-padding;
          vertical-align: top;

          // frozen columns
          &.p-frozen-column {
            background-color: #fff;
          }
        }

        // grouped rows
        th[scope='row'] {
          padding: $table-cell-padding;
          @include table-headers;
        }

        // draggable
        &[draggable] {
          background-color: #fff;
        }

        &:has(.p-checkbox input:checked) {
          background-color: var(--rich100);

          .p-frozen-column {
            background-color: var(--rich100);
          }
        }
      }
    }

    // scrollable
    &.p-datatable-scrollable-table {
      border-collapse: separate;

      .p-datatable-thead {
        tr {
          th {
            background-color: #fff;
            border-bottom: 1px solid var(--border_color);
          }
        }
      }
    }
  }

  .p-column-resizer-helper {
    background: var(--focus_border_color) !important;
  }

  // hoverable rows
  &.p-datatable-hoverable-rows {
    .p-datatable-tbody {
      > tr {
        &:not(.p-highlight) {
          &:hover {
            background: var(--rich200);

            .p-frozen-column {
              background-color: var(--rich200);
            }
          }
        }
      }
    }
  }

  // striped
  &.p-datatable-striped {
    .p-datatable-tbody {
      > tr {
        &:nth-child(even) {
          background-color: var(--rich100);
        }
      }
    }
  }

  // row toggle
  .table-row-toggle {
    color: var(--text);
    padding: 4px;

    i.pbi-icon-mini {
      padding-right: 0;
    }
  }

  // gridlines
  &.p-datatable-gridlines {
    .p-datatable-thead {
      > tr {
        > th {
          border-top: solid;
          border-width: 1px 0px 1px 1px;
          border-color: var(--border_color);
        }
      }
    }

    &:has(.p-datatable-thead):has(.p-datatable-tbody) .p-datatable-tbody > tr > td {
      border-width: 0px 0px 1px 1px;
      border-color: var(--border_color);
    }
  }

  .table-details-content {
    padding: 10px;
  }

  // editable
  p-celleditor {
    border-bottom: 1px dashed var(--hyperlink);
    color: var(--hyperlink);
  }

  // advanced table
  &.advanced-table {
    .p-datatable-wrapper {
      flex-grow: 1;
    }

    .p-paginator-bottom {
      margin: 0;
      padding: 16px 0px 16px 0px;
    }
  }
}
