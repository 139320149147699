/*
 * FileUpload
 */

.p-fileupload-buttonbar {
  padding: 0;
  border: 0;
  background-color: transparent;

  .p-fileupload-choose.p-state-disabled input {
    cursor: default;
  }

  .p-button {
    margin-right: 0.25rem;
    vertical-align: middle;
  }

  .toolbar-choose-files {
    color: var(--hyperlink) !important;
  }

  .bgdark & {
    color: var(--dark_fg);
  }
}

.p-fileupload-content {
  position: inherit;
  padding: 1rem;
  margin-top: 1rem;
  border: 3px dashed $border-color;
  background-color: white;
  transition: border-color 0.3s;

  .bgwhite & {
    background-color: var(--rich_bg_color);
  }

  .bgdark & {
    background-color: var(--dark_bg);
  }

  &.p-fileupload-highlight {
    border-color: var(--primary);
  }

  .p-messages {
    position: relative;
    padding: 1rem 2rem 1rem 1rem;
    margin-bottom: 1rem;
    border-radius: 6px;
    color: var(--text);

    ul {
      margin-bottom: 0;
    }

    .p-messages-close {
      position: absolute;
      top: 1rem;
      right: 1rem;
      color: var(--text);
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }

    .p-messages-icon {
      display: none;
    }

    &.p-messages-error {
      border: 1px solid var(--danger);
      background-color: var(--danger_bg);
    }
  }
}

.p-fileupload-files {
  display: table;

  img {
    border: none;
  }
}

.p-fileupload-row {
  display: table-row;

  > div {
    display: table-cell;
    padding: 0.5em 1em;
    vertical-align: middle;
  }
}

.p-fileupload-content {
  .p-progressbar {
    position: absolute;
    top: 1px;
    left: 0;
    width: 100%;
    height: 0.25em;
    border: 0 none;
  }

  .p-progressbar-value {
    border-color: var(--primary);
    border-radius: 0;
  }
}

/* Simple */

.p-fileupload-choose {
  position: relative;
  overflow: hidden;

  input[type='file'] {
    position: absolute;
    top: 0;
    right: 0;
    min-height: 100%;
    margin: 0;
    cursor: pointer;
    direction: ltr;
    filter: alpha(opacity=0);
    font-size: 100px;
    opacity: 0;
    text-align: right;
  }

  &.p-fileupload-choose-selected input[type='file'] {
    display: none;
  }
}

/* p-fluid */

.p-fluid {
  .p-fileupload .p-button {
    width: auto;
  }

  .p-fileupload-content .p-button-icon-only {
    width: 2em;
  }
}

// file upload  drag and drop

.file-upload-drag-drop {
  background-color: transparent;
  position: relative;

  .p-fileupload-buttonbar {
    position: relative;
    color: var(--rich500);

    .p-fileupload-choose {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0;
      border: 1px dashed var(--rich400);

      &:hover {
        background-color: transparent;
      }

      &.p-disabled {
        opacity: 0.5;

        + div {
          opacity: 0.5;
        }
      }

      span,
      .p-element.p-icon-wrapper .p-icon {
        // hides the primeng <plusicon>
        display: none;
      }

      input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin: 0;
        font-size: 0;
        opacity: 0;
      }
    }

    .toolbar-choose-files {
      color: var(--hyperlink) !important;
    }
  }

  .p-fileupload-content {
    position: inherit;
    padding: 0;
    margin-top: 12px;
    border: none;
    border: none !important;
    -webkit-transition: border-color 0.3s;
    transition: border-color 0.3s;
    display: block;
    background-color: var(--rich100);

    &.p-fileupload-highlight {
      .content-drag-drop {
        background-color: var(--primary);
        opacity: 0.125;
      }
    }

    .content-drag-drop {
      position: absolute;
      top: -49px;
      left: 1px;
      height: 36px;
      width: 100%;
      cursor: pointer;
    }

    .p-progressbar {
      background-color: transparent;
    }

    .p-messages {
      background-color: transparent;
      margin: 0 0 0 0;
      color: var(--gray700);
      padding: 1rem;

      .p-message {
        border: 1px solid #d30c0c;
        background-color: #fdf2f2;
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI2IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHdpZHRoPSIyNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAtMSAyNSAyNSkiPjxjaXJjbGUgY3g9IjEyIiBjeT0iMTIiIHI9IjEyIiBzdHJva2U9IiNERTBGMEUiIHN0cm9rZS13aWR0aD0iMiIvPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDExIDUpIj48Y2lyY2xlIGN4PSIxIiBjeT0iMSIgZmlsbD0iI0RFMEYwRSIgcj0iMSIvPjxwYXRoIGQ9Im0xIDV2OCIgc3Ryb2tlPSIjREUwRjBFIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIvPjwvZz48L2c+PC9zdmc+');
        background-position: 1rem center;
        background-repeat: no-repeat;
        background-size: 1.5rem 1.5rem;
        padding: 1rem 1rem 1rem 3.4rem;
        border-radius: 2px;

        .p-message-wrapper {
          display: block;

          .p-message-icon {
            display: none;
          }

          .p-message-summary {
            word-break: break-word;
            font-weight: bold;
          }

          .p-mesages-detail {
            word-break: break-word;
          }

          .p-message-close {
            display: none;
          }
        }
      }

      &:empty {
        display: none;
      }

      ul {
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;
        padding-right: 1rem;
        font-size: 0.75rem;

        li {
          margin-top: 0.5rem;
          margin-bottom: 0;
          line-height: normal;

          &:first-of-type {
            margin-top: 0;
          }
        }
      }
    }

    .file-remove {
      color: var(--text);
      display: inline-block;
      cursor: pointer;
      font-size: 0.5rem;
      vertical-align: middle;
      position: absolute;
      right: 0;
      top: 4px;
    }

    .p-fileupload-files {
      margin-top: 0;
      font-size: 0.75rem;
      display: block;
      padding: 10px;

      .file {
        margin-bottom: 0.5rem;
        padding-bottom: 5px;
        word-break: break-all;
        position: relative;
        display: block;
        border-bottom: 1px solid var(--divider_color);

        .file-remove {
          border-top: 1px solid var(--divider_color);
          // padding-top: 5px;
          // top: 6px;
          border: none;
          background: transparent;
        }
      }
    }
  }
}

.case-attachments-buttons {
  background-color: var(--rich100);
}
