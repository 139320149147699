@mixin alert-link($color: var(--hyperlink)) {
  border: 0;
  // background-color: inherit;
  color: $color;
  font-weight: inherit;
  // text-decoration: underline;
}

@mixin alert($bg, $bdr, $icon, $text: var(--text)) {
  border-color: $bdr;
  background-color: $bg;
  background-image: url($icon);
  border-radius: $border-radius;
  color: $text;

  i {
    color: $bdr;
  }

  a,
  .alert-link {
    @include alert-link;
  }
}

@mixin alert-hicontrast($icon) {
  border: 0;
  background-color: #131933;
  background-image: url($icon), linear-gradient(127deg, #131933 0%, #42042e 100%);
  border-radius: 0;
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

$alertTypes: (
  info: var(--info),
  success: var(--success),
  warning: var(--warning),
  error: var(--danger),
);

// all alerts
.alert {
  padding: 1rem 1rem 1rem 3rem;
  background-position: 1rem 0.9rem;
  background-repeat: no-repeat;
  background-size: 1.5rem 1.5rem;
  border-radius: 0;

  .close {
    width: 1rem;
    height: 1rem;
    background-image: url($icon-close);
    background-position: center center;
    background-repeat: no-repeat;
    color: var(--text);
    opacity: 0.8;

    hover: {
      opacity: 1;
    }

    span:not(.visually-hidden) {
      display: none;
    }
  }
}

//links for all alerts
.alert-link {
  @include alert-link;
}

// this is the pre-6.0 global alert, deprecated
.alert-global {
  position: fixed;
  z-index: 15;
  right: 0;
  left: 0;
  padding-left: 2rem;
  margin: 0;
  border: none;
  background-image: none;
  border-radius: 0;
  text-align: left;

  .close {
    width: 1rem;
    height: 1rem;
    background-image: url($icon-close-ko);
    background-position: center center;
    background-repeat: no-repeat;

    span:not(.visually-hidden) {
      display: none;
    }
  }

  span {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .alert-link {
    @include alert-link(#fff);
  }

  @each $type, $color in $alertTypes {
    &.alert-#{$type} {
      background-color: $color;
      background-image: none;
      border-radius: 0;
      color: #fff;
    }
  }
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 1rem;

  .close {
    position: relative;
    padding: 0;
    color: var(--text);
    cursor: pointer;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }
  }
}

.alert-danger {
  @include alert($state-danger-bg, $state-danger-border, $icon-danger);
}

.alert-warning {
  @include alert($state-warning-bg, $state-warning-border, $icon-warning);
}

.alert-success {
  @include alert($state-success-bg, $state-success-border, $icon-success);
}

.alert-info {
  @include alert($state-info-bg, $state-info-border, $icon-info);
}

// modifier class to remove tints
.alert-notint {
  background-color: #f00;
}

.alert-noborder {
  padding: 0.5rem 1rem 0.5rem 2.25rem;
  border: 0;
  background-color: transparent;
  background-position: 0 0.4rem;
}

@mixin panel-heading-icon($icon) {
  padding-left: 2rem !important;
  background-image: url($icon);
  background-position: 1rem center;
  background-repeat: no-repeat;
}

.panel-heading-danger {
  @include panel-heading-icon($icon-danger);
}

.panel-heading-success {
  @include panel-heading-icon($icon-success);
}

.panel-heading-warning {
  @include panel-heading-icon($icon-warning);
}

.panel-heading-info {
  @include panel-heading-icon($icon-info);
}

// -- COMPACT UI --
.alert-sm {
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  margin-bottom: 0.5rem;
  background-position: 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  font-size: var(--font_size_sm);
}

ngb-alert.alert-transitory {
  .alert {
    display: inline;
    padding: 0;
    margin-left: 1rem;
    border: 0;
    background-color: transparent;
    background-image: none;
    background-position: 0 center;

    button {
      display: none;
    }

    &.alert-success {
      color: var(--success);
    }

    &.alert-danger {
      color: var(--danger);
    }

    &.alert-warning {
      color: var(--warning);
    }

    &.alert-info {
      color: var(--info);
    }
  }
}

.global-alert {
  background-color: var(--danger);
  color: var(--white);
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: auto;

  .close {
    width: 1rem;
    height: 1rem;
    background-image: url($icon-close-ko);
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    opacity: 1;
  }

  a.alert-link {
    color: #fff !important;
    text-decoration: underline;
  }

  &-success {
    background-color: var(--green700);
  }

  &-danger {
    background-color: var(--danger);
  }

  &-warning {
    background-color: var(--orangedark);
  }

  &-info {
    background-color: var(--blue);
  }
}
