.p-toast {
  position: fixed;
  width: 320px !important;
  z-index: 1060 !important;
  opacity: 1;
  .p-toast-message {
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    border-radius: $border-radius;
    background-position: 15px center;
    background-repeat: no-repeat;
    margin-bottom: 1rem;
    .p-toast-message-content {
      padding: 1em 1.5em 1em 3.5em;
      color: var(--text);
      align-items: center !important;
      .p-toast-message-text {
        margin: 0;
      }
      .p-toast-message-icon {
        display: none;
      }
      .p-toast-summary {
        font-weight: 700;
      }
      .p-toast-detail {
        // margin: 0.5rem 0 0 0;
        margin: 0;
        line-height: 1.33;
      }
    }
    .p-toast-icon-close {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background: transparent;

      transition:
        background-color 0.2s,
        color 0.2s,
        box-shadow 0.2s;
      &:hover {
        background: rgba(255, 255, 255, 0.3);
      }
      &:focus {
        // outline: 0 none;
        outline-offset: 0;
        box-shadow: 0 0 0 0.2rem #a6d5fa;
      }
    }

    &.p-toast-message-info {
      background-color: $state-info-bg;
      border: 1px solid $state-info-border;
      background-image: url($icon-info);
    }
    &.p-toast-message-success {
      background-color: $state-success-bg;
      border: 1px solid $state-success-border;
      background-image: url($icon-success);
    }
    &.p-toast-message-warn {
      background-color: $state-warning-bg;
      border: 1px solid $state-warning-border;
      background-image: url($icon-warning);
    }
    &.p-toast-message-error {
      background-color: $state-danger-bg;
      border: 1px solid $state-danger-border;
      background-image: url($icon-danger);
    }
  }
}
.p-toast-message {
  overflow: hidden;
}
.p-toast-message-content {
  display: flex;
  align-items: flex-start;
}
.p-toast-message-text {
  flex: 1 1 auto;
}
.p-toast-top-right {
  top: 20px;
  right: 20px;
}
.p-toast-top-left {
  top: 20px;
  left: 20px;
}
.p-toast-bottom-left {
  bottom: 20px;
  left: 20px;
}
.p-toast-bottom-right {
  bottom: 20px;
  right: 20px;
}
.p-toast-top-center {
  top: 20px;
  left: 50%;
  margin-left: -10em;
}
.p-toast-bottom-center {
  bottom: 20px;
  left: 50%;
  margin-left: -10em;
}
.p-toast-center {
  left: 50%;
  top: 50%;
  min-width: 20vw;
  transform: translate(-50%, -50%);
}
.p-toast-icon-close {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}
.p-toast-icon-close.p-link {
  cursor: pointer;
}
