.fake-modal {
  .modal-dialog {
    --bs-modal-width: 500px;
    margin: 0;
  }

  .modal-content {
    box-shadow: none;
  }
}
