.p-helper-hidden-accessible {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  margin: -1px;
  clip: rect(0 0 0 0);
}

.p-widget-header {
  border: $border-width solid var(--border-color);
  background: var(--rich_bg_color);
  color: var(--text);
  font-weight: normal;
}

.p-widget {
  background-color: white;
  font-size: 1em;
}

.p-dialog {
  position: fixed;
  z-index: 1002;
  width: 40vw;
  min-width: 200px;
  min-height: 150px;
  padding: 0;

  &.p-shadow {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }

  &.p-widget-content {
    overflow: auto;
    border: 1px solid var(--border_color);
    background-color: transparent;
  }

  .p-widget-header {
    border: 0;
  }

  &.p-widget {
    font-size: 1em;

    .p-dialog-titlebar {
      position: relative;
      padding: 1em 1.5em;
    }

    .p-dialog-content {
      padding: 1em 1.5em;
    }

    .p-dialog-titlebar-close {
      padding: 0.125em;
      border: 1px solid transparent;
      cursor: pointer;
      float: right;
    }
  }
}

ul.p-dropdown-items {
  margin-bottom: 0;
}

// from primeng 10 common css
.p-component,
.p-component * {
  box-sizing: border-box;
}

.p-hidden {
  display: none;
}

.p-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.p-hidden-accessible input,
.p-hidden-accessible select {
  transform: scale(0);
}

.p-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.p-disabled,
.p-disabled * {
  cursor: default !important;
}

.p-component-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.p-overflow-hidden {
  overflow: hidden;
}

.p-unselectable-text {
  user-select: none;
}

.p-scrollbar-measure {
  width: 100px;
  height: 100px;
  overflow: scroll;
  position: absolute;
  top: -9999px;
}

@-webkit-keyframes p-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes p-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

input[type='button'],
input[type='submit'],
input[type='reset'],
input[type='file']::-webkit-file-upload-button,
button {
  border-radius: 0;
}

.p-link {
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;
}

.p-visually-hidden {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

/* Non ng overlay animations */
.p-connected-overlay {
  opacity: 0;
  transform: scaleY(0.8);
  transition:
    transform 0.12s cubic-bezier(0, 0, 0.2, 1),
    opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
}

.p-connected-overlay-visible {
  opacity: 1;
  transform: scaleY(1);
}

.p-connected-overlay-hidden {
  opacity: 0;
  transform: scaleY(1);
  transition: opacity 0.1s linear;
}

.p-toggleable-content.ng-animating {
  overflow: hidden;
}
