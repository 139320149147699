.page-case-create .services .service {
  border: 1px solid var(--text);
  position: relative;
  cursor: pointer;
  background-color: #fff;
}

.page-case-create .services .service :hover {
  background-color: var(--rich200);
}

.page-case-create .services .service:active:after {
  content: '';
  width: 17px;
  height: 13px;
  background-repeat: no-repeat;
  background-image: url(/assets/images/commonIcon/tip.svg);
  position: absolute;
  left: calc(50% - 6px);
  bottom: -12px;
}

.page-case-create .list-case-types li .case-type {
  border-top: none;
  border-right: 4px solid transparent;
  border-bottom: none;
  border-left: none;
  border-radius: 0;
  display: block;
  padding: 0.75rem;
  color: var(--text);
  text-decoration: none;
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--rich100);

  .bgdark & {
    color: var(--dark_fg);
    background-color: var(--dark_bg);
  }
}

.page-case-create .list-case-types {
  min-width: 250px;
  border-right: 1px solid var(--divider_color);
}

.list-case-reasons {
  margin-bottom: 0;
  // border-left: 1px solid var(--divider_color);
  // min-height: 250px;
}

.page-case-create .list-case-types li .case-type:hover {
  background-color: var(--rich200);
  cursor: pointer;

  .bgdark & {
    color: var(--white);
    background-color: var(--dark_bg_accent);
  }
}

.page-case-create .list-case-reasons li .case-reason {
  display: block;
  border: 1px solid var(--text);
  padding: 0.5rem;
  background-color: var(--white);
  color: var(--text);
  text-decoration: none;
  margin-bottom: 0;
  border-radius: 2px;

  .bgdark & {
    color: var(--white);
    background-color: var(--dark_bg);
  }
}

.page-case-create .list-case-types li {
  .case-type i[class^='pbi-icon'] {
    font-size: 1.25rem;
    vertical-align: sub;
    padding-right: 0.5rem;
  }
}

.page-case-create .list-case-reasons li .case-reason:hover {
  background-color: var(--rich200);
  cursor: pointer;

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--white);
  }
}

.page-case-create .list-case-types li .case-type.active {
  border-right-color: var(--text);
  background-color: var(--rich200);
  outline: none;

  .bgdark & {
    background-color: var(--dark_bg_accent);
    color: var(--white);
    border-right-color: var(--white);
  }
}

.page-case-create .list-case-reasons li .case-reason.active {
  color: #fff;
  background-color: var(--text);
  cursor: default;
}

.page-case-create .optional-value {
  font-size: 10px;
  font-style: italic;
  padding-right: 5px;
}
