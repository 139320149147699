:root {
  --mat-menu-item-label-text-font: var(--font_family_base);
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0s;
  // --mat-menu-item-label-text-line-height: 24px;
  // --mat-menu-item-label-text-weight: 400;
  --mat-menu-item-label-text-color: var(--text);
  // --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: var(--rich200);
  --mat-menu-item-focus-state-layer-color: var(--rich200);
  // --mat-menu-container-color: white;
  // --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
  --mat-menu-container-shape: var(--border_radius);
  // --mat-menu-divider-bottom-spacing: 0;
  // --mat-menu-divider-top-spacing: 0;
  // --mat-menu-item-spacing: 16px;
  // --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 1rem;
  --mat-menu-item-trailing-spacing: 1rem;
  // --mat-menu-item-with-icon-leading-spacing: 16px;
  // --mat-menu-item-with-icon-trailing-spacing: 16px;

  .mat-mdc-menu-panel {
    box-shadow: none !important;
    border: 1px solid var(--input_border_color);
    background-clip: border-box;

    .mat-mdc-menu-content {
      -webkit-font-smoothing: subpixel-antialiased;
      padding: 0;

      // increase right padding if any menu item has a submenu icon
      &:has(.mat-mdc-menu-submenu-icon) {
        @at-root :root {
          --mat-menu-item-trailing-spacing: 3rem;
        }
      }

      .divider {
        overflow: hidden;
        height: 1px;
        margin: 0;
        background-color: var(--border_color);
      }

      .mat-mdc-menu-item {
        min-height: 10px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        .mat-mdc-menu-item-text {
          -webkit-font-smoothing: subpixel-antialiased;
        }

        &.mat-mdc-menu-item-submenu-trigger {
          ::after {
            content: '\eb47';
            font-family: 'pbi-icon-outline';
            display: block;
            position: absolute;
            top: 8px;
            right: 8px;
            display: inline-block;
          }
        }

        .mat-mdc-menu-submenu-icon {
          display: none;

          &:before {
            content: 'x';
          }
        }
      }
    }
  }

  .cdk-overlay-container {
    .cdk-overlay-connected-position-bounding-box {
      .mat-mdc-menu-panel {
        margin-top: -1px;
      }
    }
  }
}
