// for the item buttons

.item-selector-button {
  position: relative;
  width: 160px;
  height: 120px;
  padding: 15px;
  border: 1px solid var(--border_color);
  border-radius: $border-radius;
  color: #000;
  cursor: pointer;
  text-align: center;
  transition:
    all ease-in-out 0.1s,
    box-shadow ease-in-out 0.1s;

  .title {
    padding-top: -3px;
    margin: 0;
    color: var(--primary);
    font-family: var(--font_family_brand_medium);
    font-size: 12px;
  }

  .divider {
    width: 20px;
    height: 1px;
    margin: 4px auto 10px;
    background-color: var(--border_color);
  }

  .image {
    width: 64px;
    height: 57px;
    margin: 4px auto;
    background: transparent url($icon-box) center center no-repeat;
  }

  &:hover,
  &.hover {
    padding: 14px;
    // border-color: var(--focus_border_color);
    box-shadow: var(--focus_shadow);
    @include arrow-inside;
    @include arrow-outside;
  }

  &:active,
  &.active {
    border-width: 2px;
    border-color: var(--primary);
    background-color: var(--primary);
    @include arrow-outside(var(--primary));

    .title {
      color: #fff;
    }

    .divider {
      background-color: #fff;
    }

    .image {
      background-color: var(--primary);
    }

    &:hover {
      padding: 15px;
      box-shadow: none;
      @include arrow-inside(var(--primary));
    }
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;

    .title {
      color: #c3c3c3;
    }

    .image {
      background-image: url($icon-box-disabled);
    }

    &:hover {
      padding: 15px;
      border: 1px solid var(--border_color);
      box-shadow: none;
    }
  }

  &:focus,
  &.focus {
    @include focused;

    @include arrow-inside;
    @include arrow-outside;
  }
}
