.welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  height: 100vh;
  @include gradient-angled(var(--grad_start), var(--grad_mid), var(--grad_end));

  @include media-breakpoint-down(md) {
    display: block;
  }

  &.welcome-navigation {
    .navigation {
      h3 {
        border-bottom: 1px solid var(--divider_color);
        color: var(--text);
      }
    }
  }

  // product background image page
  &.welcome-bgimg {
    > div {
      max-width: 1000px;
    }
  }

  // product image page
  &.welcome-productimage {
    > div {
      max-width: 1000px;
    }
  }

  // the "3 column" page
  &.welcome-threecolumn {
    .flex-basis-0 {
      flex-basis: 0 !important;
    }

    .btn-circle {
      min-width: 47px;
      font-size: 18px;
      line-height: 47px;
      color: #fff;
    }
  }

  input {
    border-color: rgba(#fff, 0.8);
    background-color: rgba(#fff, 0.1);
    color: #fff !important;

    &:focus {
      border-color: rgba(#fff, 1);
      background-color: rgba(#fff, 0.18);
      color: #fff !important;
    }

    &::-webkit-input-placeholder {
      color: rgba(#fff, 0.5);
    }

    &::-moz-placeholder {
      color: rgba(#fff, 0.5);
    }

    &:-ms-input-placeholder {
      color: rgba(#fff, 0.5);
    }
  }
}
