.line-clamp,
.line-clamp-xs,
.line-clamp-sm,
.line-clamp-lg {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
}

.line-clamp-xs {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}

.line-clamp-sm {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.line-clamp {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.line-clamp-lg {
  -webkit-line-clamp: 5;
  line-clamp: 5;
}
