.ngb-dp-weekday.small {
  color: var(--text);
  font-style: normal;
}

.ngb-dp-header,
.ngb-dp-month-name,
.ngb-dp-weekdays {
  background-color: var(--rich100) !important;
  color: var(--text);
}

.ngb-dp-day {
  .btn-light {
    color: var(--text);
    border-color: transparent;
    background-color: transparent;
    font-size: var(--font_size_base);
  }
  &.ngb-dp-today {
    .btn-light,
    .btn-light:active {
      border-color: var(--rich200);
      background-color: var(--rich200);
    }
  }
  .custom-day {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    padding: 0.185rem 0.25rem;
    text-align: center;

    &.range,
    &:hover {
      background-color: var(--btn_primary);
      color: #fff;
    }

    &.faded {
      background-color: var(--btn_secondary_bg_active);
      color: var(--text);
    }
  }

  &.disabled {
    color: var(--disabled);

    &:hover {
      .custom-day {
        &:hover {
          background-color: transparent;
          color: inherit;
        }
      }
    }
  }
}

.pbds-daterange-popover {
  flex-wrap: nowrap;

  input {
    &.form-control {
      min-width: 170px;
    }
  }
}

.daterange-popover {
  max-width: none;

  .popover-body {
    padding: 1.5rem;

    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    .ngb-dp-months {
      flex-direction: column;
    }
  }
}
@media (forced-colors: active) {
  .custom-select {
    background: #fff
      url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23ffffff' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      right 0.75rem center/8px 10px no-repeat;
    border: 1px solid #fff;
  }
}

// timepicker
.ngb-tp {
  // reduce size of arrow buttons
  .btn-link {
    padding: 0;
  }
}

.time-field-container {
  display: flex;
  gap: 1rem;
  align-items: flex-end;

  input[type='text'] {
    height: calc(1.5em + 1rem + 2px);
  }
}
