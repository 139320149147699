@font-face {
  font-family: 'PrecisionSans_W_Rg';
  src: url('../fonts/PrecisionSans_W_Rg.eot');
  src:
    url('../fonts/PrecisionSans_W_Rg.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_Rg.woff') format('woff'),
    url('../fonts/PrecisionSans_W_Rg.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_Rg.svg#PrecisionSans_W_Rg') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_Bd';
  src: url('../fonts/PrecisionSans_W_Bd.eot');
  src:
    url('../fonts/PrecisionSans_W_Bd.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_Bd.woff') format('woff'),
    url('../fonts/PrecisionSans_W_Bd.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_Bd.svg#PrecisionSans_W_Bd') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_RgIt';
  src: url('../fonts/PrecisionSans_W_RgIt.eot');
  src:
    url('../fonts/PrecisionSans_W_RgIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_RgIt.woff') format('woff'),
    url('../fonts/PrecisionSans_W_RgIt.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_RgIt.svg#PrecisionSans_W_RgIt') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_BdIt';
  src: url('../fonts/PrecisionSans_W_BdIt.eot');
  src:
    url('../fonts/PrecisionSans_W_BdIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_BdIt.woff') format('woff'),
    url('../fonts/PrecisionSans_W_BdIt.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_BdIt.svg#PrecisionSans_W_BdIt') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_Lt';
  src: url('../fonts/PrecisionSans_W_Lt.eot');
  src:
    url('../fonts/PrecisionSans_W_Lt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_Lt.woff') format('woff'),
    url('../fonts/PrecisionSans_W_Lt.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_Lt.svg#PrecisionSans_W_Lt') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_LtIt';
  src: url('../fonts/PrecisionSans_W_LtIt.eot');
  src:
    url('../fonts/PrecisionSans_W_LtIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_LtIt.woff') format('woff'),
    url('../fonts/PrecisionSans_W_LtIt.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_LtIt.svg#PrecisionSans_W_LtIt') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_Md';
  src: url('../fonts/PrecisionSans_W_Md.eot');
  src:
    url('../fonts/PrecisionSans_W_Md.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_Md.woff') format('woff'),
    url('../fonts/PrecisionSans_W_Md.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_Md.svg#PrecisionSans_W_Md') format('svg');
}
@font-face {
  font-family: 'PrecisionSans_W_MdIt';
  src: url('../fonts/PrecisionSans_W_MdIt.eot');
  src:
    url('../fonts/PrecisionSans_W_MdIt.eot?#iefix') format('embedded-opentype'),
    url('../fonts/PrecisionSans_W_MdIt.woff') format('woff'),
    url('../fonts/PrecisionSans_W_MdIt.ttf') format('truetype'),
    url('../fonts/PrecisionSans_W_MdIt.svg#PrecisionSans_W_MdIt') format('svg');
}
