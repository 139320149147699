.p-checkbox {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
  vertical-align: bottom;

  .p-checkbox-box {
    width: 1rem;
    height: 1rem;
    border: 1px solid var(--btn_primary);
    background-color: var(--white);
    border-radius: 2px;
    line-height: 1.125em;
    display: flex;
    justify-content: center;
    align-items: center;

    .bgwhite & {
      background-color: var(--rich100);
    }

    .bgdark & {
      background-color: var(--dark_input_bg);
    }

    &.p-highlight {
      // background-image: $custom-checkbox-indicator-icon-checked;
      background-color: var(--btn_primary);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 50% 50%;
      color: #fff;

      .pi {
        display: none;
      }
    }

    &.p-indeterminate {
      background-color: var(--btn_primary);
      color: #fff;
      line-height: 0.125rem;
    }

    &.p-focus {
      @include focused;
    }
    &:not(.p-indeterminate) .p-icon-wrapper .p-checkbox-icon {
      // hides the primeng <checkicon>
      display: none;
    }
  }
}

p-checkbox {
  display: inline-flex;
  vertical-align: bottom;
  align-items: center;
}

.p-checkbox-label {
  line-height: 1;
}
