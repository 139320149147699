pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}
code.hljs {
  padding: 3px 5px
}
/*!
  Theme: nnfx dark
  Description: a theme inspired by Netscape Navigator/Firefox
  Author: (c) 2020-2021 Jim Mason <jmason@ibinx.com>
  Maintainer: @RocketMan
  License: https://creativecommons.org/licenses/by-sa/4.0  CC BY-SA 4.0
  Updated: 2021-05-17

  @version 1.1.0
*/
.hljs {
  background: #333;
  color: #fff
}
.language-xml .hljs-meta,
.language-xml .hljs-meta-string {
  font-weight: bold;
  font-style: italic;
  color: #69f
}
.hljs-comment,
.hljs-quote {
  font-style: italic;
  color: #9c6
}
.hljs-name,
.hljs-keyword,
.hljs-built_in {
  color: #a7a
}
.hljs-name,
.hljs-attr {
  font-weight: bold
}
.hljs-string {
  font-weight: normal
}
.hljs-code,
.hljs-string,
.hljs-meta .hljs-string,
.hljs-number,
.hljs-regexp,
.hljs-link {
  color: #bce
}
.hljs-title,
.hljs-symbol,
.hljs-bullet,
.hljs-variable,
.hljs-template-variable {
  color: #d40
}
.hljs-title.class_,
.hljs-class .hljs-title,
.hljs-type {
  font-weight: bold;
  color: #96c
}
.hljs-title.function_,
.hljs-function .hljs-title,
.hljs-attr,
.hljs-subst,
.hljs-tag {
  color: #fff
}
.hljs-formula {
  background-color: #eee;
  font-style: italic
}
.hljs-addition {
  background-color: #797
}
.hljs-deletion {
  background-color: #c99
}
.hljs-meta {
  color: #69f
}
.hljs-section,
.hljs-selector-id,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-selector-tag {
  font-weight: bold;
  color: #69f
}
.hljs-selector-pseudo {
  font-style: italic
}
.hljs-doctag,
.hljs-strong {
  font-weight: bold
}
.hljs-emphasis {
  font-style: italic
}