.pbds-page-header {
  background-color: #fff;

  .bgdark & {
    border-bottom-color: var(--dark_input_border) !important;
  }

  &.pbds-page-header-margin {
    margin-top: 70px;

    @include media-breakpoint-down(lg) {
      margin-top: 60px;
    }
  }

  .bgdark & {
    background-color: #2a3037;
  }

  .pbds-page-header-back-link {
    margin-top: -3px;
  }

  &-navigation {
    &:has([class*='col']:empty) {
      display: none;
    }
  }

  &-middle {
    &:empty {
      display: none;
    }
  }

  &-right {
    &:empty {
      display: none;
    }
  }
}
