h3.popover-header {
  margin-top: 0;

  .bgdark & {
    background-color: var(--dark_bg_accent);
  }
}

.popover {
  top: 0;
  left: 0;
  z-index: 1060;
  // max-width: 276px;
  font-family: var(--font_family_base);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.749875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid var(--border_color);
  border-radius: 4px;

  .bgdark & {
    background-color: var(--dark_input_bg);
  }
}

.popover-gray {
  color: var(--gray500);
}

.popover-default {
  color: var(--hyperlink);
}

// override bootstrap

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  background-color: var(--rich100);
  font-size: 14px;
  border-bottom: 1px solid var(--border_color);
  font-family: var(--font_family_base);
  font-weight: bold;
  border-top-left-radius: var(--border_radius);
  border-top-right-radius: var(--border_radius);
}

.popover-body {
  color: var(--text);
  padding: 0.5rem 0.75rem;
}

a[data-toggle='popover'],
a[ngbPopover] {
  .pbi-icon-mini {
    color: var(--text);
  }

  // just in case
  .text-gray-500 {
    color: var(--text) !important;
  }
}

.tooltip-inner {
  // max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 2px;
  font-family: var(--font_family_sans_serif);
  border-radius: var(--border_radius);
  font-size: 12px;
}

.bgdark {
  .tooltip-inner {
    background-color: var(--dark_tooltip);
  }

  .bs-tooltip-top .arrow::before {
    border-top-color: var(--dark_tooltip);
  }

  .bs-tooltip-bottom .arrow::before {
    border-bottom-color: var(--dark_tooltip);
  }

  .bs-tooltip-left .arrow::before {
    border-left-color: var(--dark_tooltip);
  }

  .bs-tooltip-right .arrow::before {
    border-right-color: var(--dark_tooltip);
  }
}
