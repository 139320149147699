// .p-icon-field-left .p-input-icon:first-of-type {
//   left: 0.85rem;
// }

// .p-icon-field-right .p-input-icon:last-of-type {
//   right: 0.85rem;
// }

// .p-icon-field .p-input-icon {
//   position: absolute;
//   top: 50%;
//   margin-top: -0.45rem;
//   font-size: 1rem;
// }

// .p-input-icon-left > .p-icon-wrapper.p-icon,
// .p-input-icon-left > i:first-of-type {
//   left: 0.95rem;
// }

// .p-input-icon-left > .p-inputtext {
//   padding-left: 2.6rem;
// }

// .p-input-icon-right > .p-inputtext {
//   padding-right: 2.6rem;
// }

// .p-icon-field-left > .p-inputtext {
//   padding-left: 2.6rem;
// }

// .p-inputtext:enabled:hover {
//   border-color: #94a3b8;
// }

// .p-inputtext:enabled:focus {
//   @include focused;
// }

.p-icon-field {
  display: block;

  &.p-icon-field-left {
    .p-input-icon:first-of-type {
      left: 0.85rem;
    }

    .p-inputtext {
      padding-left: 2.6rem;
    }
  }

  &.p-icon-field-right {
    .p-input-icon:last-of-type {
      right: 0.85rem;
    }

    .p-inputtext {
      padding-right: 2.6rem;
    }
  }

  .p-inputtext {
    color: var(--text);
    padding: 0.5rem 0.75rem;
    border-radius: var(--border_radius);

    &::placeholder {
      color: var(--placeholder_color);
    }

    &:enabled:focus {
      @include focused;
    }
  }

  .p-input-icon {
    position: absolute;
    top: 50%;
    margin-top: -0.45rem;
    font-size: 1rem;
  }

  @at-root {
    p-iconfield {
      &.input-group-search {
        :has(.ng-select) {
          .p-input-icon {
            z-index: 1002;
          }

          .ng-select {
            &-container {
              background-color: var(--rich100);
              padding-left: 2rem;

              .bgdark & {
                background-color: var(--dark_bg);
              }
            }

            &.ng-select-opened {
              .ng-select-container {
                background-color: var(--rich100);

                .bgdark & {
                  background-color: var(--dark_bg);
                }
              }
            }

            .ng-value-container {
              .ng-input {
                & > input {
                  padding-left: 2rem !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
