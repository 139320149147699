.table-tracking-timeline {
  .event-title {
    color: var(--text);
  }

  tbody {
    tr:hover {
      .posession-next-delivered {
        &::after {
          border-color: var(--rich200);
        }
      }
    }
  }

  .table-event-details {
    margin: 0;

    tbody {
      tr {
        &:hover {
          color: inherit;
          background-color: initial;
        }

        td {
          padding-top: 0;
          padding-bottom: 8px;

          &:first-child {
            text-transform: uppercase;
            font-weight: 300;
            font-size: 0.75rem;
            letter-spacing: 0.1rem;
            padding-left: 0;
            width: 1px;
          }
        }
      }
    }
  }

  .posession {
    --offset: 20px;
    --radius: 50px;

    --usps: #578cf1;
    --uspsSolid: linear-gradient(180deg, var(--usps) 0px, var(--usps) 100%);
    --uspsSolidOffset: linear-gradient(180deg, transparent var(--offset), var(--usps) 25px, var(--usps) 100%);

    --pb: #3e53a4;

    --pbSolid: linear-gradient(180deg, var(--pb) 0px, var(--pb) 100%);
    --pbSolidOffset: linear-gradient(180deg, transparent var(--offset), var(--pb) 25px, var(--pb) 100%);

    --carrier: #a271df;

    --carrierSolid: linear-gradient(180deg, var(--carrier) 0px, var(--carrier) 100%);
    --carrierSolidOffset: linear-gradient(180deg, transparent var(--offset), var(--carrier) 25px, var(--carrier) 100%);

    --client: #9b9b9b;
    --clientSolid: linear-gradient(180deg, var(--client) 0px, var(--client) 100%);
    --clientSolidOffset: linear-gradient(180deg, transparent var(--offset), var(--client) 25px, var(--client) 100%);

    width: 16px;
    position: relative;
    padding-left: 0px !important;
    padding-right: 0px !important;

    &::after {
      content: '';
      position: absolute;
      display: block;
      left: 3px;
      border: 2px solid #fff;
      height: 10px;
      width: 10px;
      border-radius: var(--radius);
      margin-top: 4px;
    }

    &-physical {
      &::after {
        background-color: #fff;
      }
    }

    &-usps {
      background-image: var(--uspsSolid);

      &.posession-first {
        background-image: var(--uspsSolidOffset);

        &.posession-next-usps {
          &::before {
            background-image: var(--uspsSolid);
          }
        }
      }

      &.posession-next-delivered {
        background-image: var(--uspsSolidOffset);
      }
    }

    &-pitney-bowes {
      background-image: var(--pbSolid);

      &.posession-first {
        background-image: var(--pbSolidOffset);

        &.posession-next-pitney-bowes {
          &::before {
            background-image: var(--pbSolid);
          }
        }
      }
    }

    &-carrier {
      background-image: var(--carrierSolid);

      &.posession-first {
        background-image: var(--carrierSolidOffset);

        &.posession-next-carrier {
          &::before {
            background-image: var(--carrierSolid);
          }
        }
      }
    }

    &-client {
      background-image: var(--clientSolid);

      &.posession-first {
        background-image: var(--clientSolidOffset);

        &.posession-next-usps {
          &::before {
            background-image: var(--clientSolid);
          }
        }
      }
    }

    &-first {
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 10px;
        left: 0;
        right: 0;
        height: 22px;
        border-top-left-radius: var(--radius);
        border-top-right-radius: var(--radius);
      }

      &.posession-next {
        &::before {
          top: 12px;
          height: 18px;
          border-bottom-left-radius: var(--radius);
          border-bottom-right-radius: var(--radius);
          border-top-left-radius: var(--radius);
          border-top-right-radius: var(--radius);
        }
      }
    }

    &-next {
      &::before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        right: 0;
        height: 30px;
        border-bottom-left-radius: var(--radius);
        border-bottom-right-radius: var(--radius);
      }

      &-delivered {
        &::after {
          content: '';
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'pbi-icon-mini' !important;
          font-size: 10px;
          color: #fff;
          background-color: #00b140;
          border-color: #fff;
          border-width: 4px;
          width: 28px;
          height: 28px;
          margin-top: -3px;
          left: -6px;
        }
      }

      &-usps {
        &::before {
          background-image: var(--uspsSolid);
        }
      }

      &-pitney-bowes {
        &::before {
          background-image: var(--pbSolid);
        }
      }

      &-carrier {
        &::before {
          background-image: var(--carrierSolid);
        }
      }

      &-client {
        &::before {
          background-image: var(--clientSolid);
        }
      }

      &-delivered {
        &::before {
          height: 100%;
          border-radius: 0;
          top: 20px;
        }
      }
    }
  }
}
