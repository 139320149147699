.dashboard {
  h1 {
    margin-top: 100px;
    .subhead {
      display: block;
      margin-left: 32px;
    }
  }
  .container h2:first-of-type {
    margin-top: 100px;
    .subhead {
      display: block;
      margin-left: 32px;
    }
  }

  .toolbar {
    background-color: transparent;
    border-top: 0;
    padding-bottom: 0.25rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    .input-group-append {
      .btn-outline-secondary {
        border: 0;
      }
    }

    .form-label {
      margin-bottom: 0;
      line-height: 1;
      font-size: 10px;
      padding-left: 5px;
      padding-top: 6px;
      display: block;
    }
    .form-control:not([ngbdatepicker]) {
      border: 1px solid transparent;
      padding: 0.2rem 22px 0.2rem 0.25rem;
      // background-color: transparent;
      height: auto;
      border-radius: 0;
    }
    .form-control[ngbdatepicker] {
      min-width: 180px;
    }

    // ng bootstrap
    .dropdown {
      .dropdown-menu {
        width: 100%;
        border: 1px solid var(--border_color);
        box-shadow: none;
      }
      .btn.dropdown-toggle {
        width: 100%;
        background-color: transparent;
        text-align: left;
        font-family: var(--font_family_base);
        font-size: var(--font_size_base);
        color: var(--text);
        border: 1px solid transparent;
        border-radius: 0;
        padding: 0.2rem 22px 0.2rem 0.25rem;

        @include menu-droparrow;

        &:focus {
          border-color: var(--focus_border_color);
          box-shadow: var(--focus_shadow);
        }

        &::after {
          border: 0;
        }
      }
    }
  }

  .gauge-stack {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    > div {
      padding: 1rem;
    }
  }
}
