.pbds-toolbar {
  border-bottom: 1px solid var(--border_color);

  &-left {
    &:empty {
      display: none;
    }
  }

  &-right {
    &:empty {
      display: none;
    }
  }
}
