$sidebar-width: 280px;
$list-group-item-active-border-width: 4px;
$list-group-item-active-border-color: #fff;
$list-group-item-left-indent: 40px;
$list-group-item-vertical-padding: 0.25rem;
$leftnav-background-color: #2e2e2e;
$leftnav-link-color: #9b9b9b;
$leftnav-link-active-color: #fff;
$leftnav-link-hover-color: #fff;
$leftnav-link-hover-background-color: #292929;
$leftnav-section-border: #4e4e4e;

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }

  70% {
    box-shadow: 0 0 5px 10px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.offcanvas {
  background-color: #2e2e2e;
}

.offcanvas-header {
  padding-top: 0.5rem;
  padding-bottom: 0;

  .btn {
    color: #fff;
    font-size: 18px;
    &:focus {
      outline-offset: -1px;
    }
  }
}

.site-sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  border: 0;
  display: flex;
  width: $sidebar-width;
  flex-direction: column;
  background-color: $leftnav-background-color;

  a.list-group-item {
    padding-top: $list-group-item-vertical-padding;
    padding-bottom: $list-group-item-vertical-padding;
    padding-left: $list-group-item-left-indent;
    border: 0;
    background-color: transparent;
    border-radius: 0;
    color: $leftnav-link-color;
    font-family: var(--font_family_brand_regular);
    font-size: var(--font_size_base);
    font-weight: normal;

    &:hover {
      background-color: $leftnav-link-hover-background-color;
      color: $leftnav-link-hover-color;
      text-decoration: none;
    }

    &.active {
      padding-left: $list-group-item-left-indent - $list-group-item-active-border-width;
      border-left: $list-group-item-active-border-width solid $list-group-item-active-border-color;
      color: $leftnav-link-active-color;

      &:hover {
        color: $leftnav-link-hover-color;
      }
    }
  }
}

.site-logo {
  align-self: center;
  padding: 0.6rem 1rem;
  text-align: center;
}

.home .sidebar-header {
  display: none;
}

// Site navigation wrapper ---------------------

.home .offcanvas-body {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: var(--text);
  }

  &::-webkit-scrollbar-thumb {
    background: $leftnav-link-color;
    border-radius: 0.5rem;
    box-shadow: var(--primary) 0 0 0 1px inset;
  }
}

.site-navigation {
  overflow: hidden;
  overflow-y: auto;

  .nav {
    display: flex;
    flex-flow: column nowrap;
  }

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: var(--text);
  }

  &::-webkit-scrollbar-thumb {
    background: $leftnav-link-color;
    border-radius: 0.5rem;
    box-shadow: var(--primary) 0 0 0 1px inset;
  }

  .section-header {
    margin-bottom: 0.25rem;
    margin-left: 2rem;
    color: $leftnav-link-color;
    font-family: var(--font_family_brand_light);
    font-size: var(--font_size_sm);
    font-weight: normal;
    text-transform: uppercase;
  }

  .accordion-body {
    padding: 0;

    .list-group {
      margin-bottom: 1rem;
      border-radius: 0;
    }
  }

  .accordion-item {
    border: 0;
    background-color: $leftnav-background-color;
    color: $leftnav-link-color;
  }

  .accordion-button {
    padding: 0.5rem 1rem 0.5rem 2rem;
    background-color: $leftnav-background-color;
    color: $leftnav-link-color;
    font-family: var(--font_family_brand_regular);
    font-size: var(--font_size_base);
    font-weight: normal;

    display: flex;
    justify-content: space-between;

    span {
      display: flex;
    }

    &.collapsed {
      color: $leftnav-link-color;
    }

    &:hover {
      color: $leftnav-link-hover-color;
    }

    &:active {
      color: #9b9b9b;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }

    &:not(.collapsed)::after {
      transform: rotate(90deg);
    }
  }

  .accordion .accordion-header .accordion-button,
  .accordion .accordion-header .btn.btn-acc {
    color: $leftnav-link-color;
  }

  .accordion .accordion-item .accordion-header {
    padding: 0.5rem 2.5rem 0.5rem 2rem;
  }

  .section-divider {
    margin: 8px 0;
    border-top: 1px solid $leftnav-section-border;
  }

  // treat list group items here like accordion headers
  .loose-links {
    padding-top: 5px;

    .list-group {
      margin-bottom: 0;

      a.list-group-item {
        padding: 0.5rem 1rem 0.5rem 2rem;
        border: 0;
        background-color: transparent;
        border-radius: 0;
        color: $leftnav-link-color;
        font-family: var(--font_family_brand_regular);
        font-size: var(--font_size_base);
        font-weight: normal;

        &:hover {
          background-color: $leftnav-link-hover-background-color;
          color: $leftnav-link-hover-color;
        }

        &.active {
          padding-left: 32px - $list-group-item-active-border-width;
          border-left: $list-group-item-active-border-width solid $list-group-item-active-border-color;
          color: $leftnav-link-active-color;
        }
      }
    }
  }

  .doc-badge-new {
    animation: pulse 3s infinite;
    box-shadow: 0 0 0 rgba(255, 255, 255, 0.4);
  }

  .section-header .doc-badge-new {
    margin-right: 1.25rem;
  }
}

.offcanvas.offcanvas-start {
  width: $sidebar-width;
  border-right: 0;

  &:focus,
  &:focus-visible {
    outline: none;
    border-right: 0;
    box-shadow: none;
  }

  .offcanvas-body {
    padding: 0;
  }
}
