.pbds-sidebar {
  background-color: var(--rich100);
  height: 100%;
  padding: 1rem 0 1rem 0;

  hr {
    opacity: 1;
    color: var(--rich300);
  }

  &:has(.pbds-sidebar-header:empty) {
    padding-top: 0;

    .pbds-sidebar-section:first-of-type {
      margin-top: 1rem;
    }
  }

  &:has(.pbds-sidebar-footer:empty) {
    padding-bottom: 0;

    .pbds-sidebar-section:last-of-type {
      margin-bottom: 1rem;
    }
  }

  .pbds-sidebar-header {
    &:empty {
      display: none;
    }
  }

  .pbds-sidebar-sections-wrapper-outer {
    overflow: hidden;
    position: relative;
    display: flex;
    height: 100%;

    &::before {
      content: '';
      display: block;
      left: 0;
      right: 14px;
      height: 1px;
      border-radius: 0;
      background-color: var(--rich300);
      position: absolute;
      z-index: 1;
    }

    &::after {
      content: '';
      display: block;
      left: 0;
      right: 14px;
      bottom: 0;
      height: 1px;
      border-radius: 0;
      background-color: var(--rich300);
      position: absolute;
      z-index: 1;
    }
  }

  .pbds-sidebar-sections-wrapper-inner {
    display: flex;
    overflow: auto;
    width: 100%;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;

    // https://css-tricks.com/books/greatest-css-tricks/scroll-shadows/
    background:
      //Shadow Cover TOP
      linear-gradient(var(--rich100) 30%, rgba(255, 255, 255, 0)) center top,
      //Shadow Cover BOTTOM
      linear-gradient(rgba(255, 255, 255, 0), var(--rich100) 70%) center bottom,
      // Shadow TOP
      radial-gradient(farthest-side at 50% 0, var(--rich300), rgba(0, 0, 0, 0)) center top,
      // Shadow BOTTOM
      radial-gradient(farthest-side at 50% 100%, var(--rich300), rgba(0, 0, 0, 0)) center bottom;

    background-repeat: no-repeat;
    background-size:
      150% 40px,
      150% 40px,
      150% 7px,
      150% 7px;
    background-attachment: local, local, scroll, scroll;

    &:before {
      content: '';
      display: block;
      left: 0;
      right: 0;
      height: 1px;
      border-radius: 0;
      background-color: var(--rich100);
      position: absolute;
      z-index: 2;
    }

    &:after {
      content: '';
      display: block;
      flex-shrink: 0;
      // left: 0;
      // right: 0;
      // bottom: 0;
      margin-top: auto;
      height: 1px;
      border-radius: 0;
      background-color: var(--rich100);
      position: relative;
      z-index: 2;
    }
  }

  .pbds-sidebar-link {
    border-right: 4px solid transparent !important;
    font-family: var(--font-family-sans-serif);
    text-decoration: none !important;
    color: var(--btn_secondary_label);
    padding: 0.25rem 1rem 0.25rem 0.5rem;
    border-radius: var(--bs-border-radius-lg) 0 0 var(--bs-border-radius-lg);
    width: 100%;
    text-align: left;

    &:hover {
      background-color: var(--rich200) !important;
    }

    &:focus {
      background-color: var(--rich200) !important;
    }

    &.active {
      background-color: var(--rich200);
      border-right-color: var(--magenta) !important;
    }
  }

  .pbds-sidebar-link-detail {
    color: var(--placeholder_color);
  }

  .pbds-sidebar-footer {
    &:empty {
      display: none;

      & {
        border: 10px solid red;
      }
    }
  }
}
