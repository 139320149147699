.form-control::placeholder {
  color: var(--placeholder_color);
  font-family: var(--font-family-sans-serif);

  .bgdark & {
    color: var(--dark_input_placeholder);
  }
}

.form-control {
  background-clip: padding-box;
  background-color: var(--white);
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border-radius: var(--border_radius);
  border: 1px solid var(--input_border_color);
  color: var(--text);
  display: block;
  font-size: var(--font_size_base);
  font-weight: 400;
  height: calc(1.5em + 1rem + 2px);
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  width: 100%;

  .bgdark & {
    background-color: var(--dark_input_bg);
    color: var(--dark_fg);
    border: 1px solid var(--dark_input_border);
  }
}

.form-control-sm {
  font-size: var(--font_size_sm);
}

.bgwhite,
.bg-white,
.signin-box {
  .form-control {
    background-color: var(--form_control_bg_richcolor);
  }
}

.bgdark,
.bgdark .signin-box {
  .form-control {
    background-color: var(--dark_input_bg);
  }
}

.form-control:disabled,
.form-control[readonly],
.form-select[readonly],
fieldset:disabled .form-control,
.bgwhite .form-control:disabled,
.bgwhite .form-control[readonly],
fieldset:disabled .form-control {
  border-color: var(--input_border_color);
  background-color: #e1e1e1;
  opacity: 1;
  pointer-events: none;
}

.form-control[readonly],
.form-select[readonly] {
  color: var(--readonly_text_color);

  .bgdark & {
    background-color: #2a3038;
    color: var(--dark_fg);
    border-color: var(--dark_input_border);
  }
}

.form-control[disabled],
fieldset[disabled] .form-control {
  color: var(--dark_input_disabled_text);
  // required on iOS
  -webkit-text-fill-color: var(--dark_input_disabled_text);
  opacity: 1;
}

.bgdark input:disabled.form-control {
  background-color: #2a3038;
  color: var(--dark_input_disabled_text);
  -webkit-text-fill-color: var(--dark_input_disabled_bg);
  border-color: var(--dark_input_border);
}

.form-control.is-invalid {
  background-image: none !important;
}

.text-area-counter {
  margin-top: 0.5rem;
  font-size: var(--font_size_sm);
  text-align: right;
}

// New BS4 treatment of form validation

.was-validated {
  .form-control:invalid,
  .form-control.is-invalid,
  .custom-select:invalid,
  .custom-select.is-invalid,
  .form-control.is-invalid:focus {
    border-color: var(--danger) !important;
    background-image: none !important;
    box-shadow: none !important;
  }

  // BS4 makes valid form controls green bodered, turn this off
  .form-control:valid,
  .form-control:valid,
  .form-control.is-valid,
  .custom-select:valid,
  .custom-select.is-valid {
    border-color: var(--input_border_color);
    background-image: none;

    .bgdark & {
      border-color: var(--dark_input_border);
    }
  }

  .form-control:focus,
  .form-control.focus,
  .form-control:valid:focus,
  .form-control.is-valid:focus,
  .form-control.focus:valid,
  .form-control.focus.is-valid {
    @include focused;
  }
}

// bootstrap also makes me do it here, too
.form-control.is-valid,
.form-control.is-invalid {
  background-image: none;
}
.form-control.is-invalid:focus {
  border-color: var(--danger) !important;
  background-image: none !important;
  box-shadow: none !important;
}

.invalid-feedback {
  color: var(--danger);
  font-size: var(--font_size_base);
}

.form-control:focus,
.form-control.focus {
  @include focused;
  border-color: var(--input_border_color) !important;
  color: var(--text) !important;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: var(--font_size_lg);
  line-height: 1.5;
  border-radius: var(--border_radius);
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.749875rem;
  line-height: 1.5;
  border-radius: var(--border_radius);
}

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 4px;
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.749875rem;
  line-height: 1.5;
  border-radius: 0;
}

.custom-file-label::after {
  border: 1px solid var(--btn_primary);
  background-color: var(--white);
  color: var(--btn_primary);
  content: 'Browse';
}

.inner-addon {
  position: relative;
  display: block;

  input {
    padding-right: 72px;
  }

  a {
    position: absolute;
    z-index: 5;
    top: 9px;
    right: 8px;
  }
}

// remove the "show" eye icon from MS edge
// https://docs.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}

.checkbox-list.form-control-sm {
  max-height: 8rem;
  overflow-y: scroll;
}

// overrides to BS4 defaults
.input-group-text {
  border: 1px solid var(--input_border_color);
  background-color: var(--white);
  color: var(--text);
  border-radius: var(--border_radius);
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  font-size: var(--font_size_sm);
  border-radius: var(--border_radius);
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  font-size: var(--font_size_lg);
  border-radius: var(--border_radius);
}

.input-group > .input-group-text {
  .bgdark & {
    color: var(--dark_fg);
    background-color: var(--dark_bg);
    border-color: var(--dark_input_border);
  }
}
.input-group-append,
.input-group-prepend {
  display: flex;
}
// NEW BS5 unsure why it cuts off the border of input group buttons. see if this causes issues.
.input-group > .btn {
  z-index: 4;
}

// removes divider between input group items
.input-group-search {
  :first-child {
    &.input-group-text {
      border-right-color: transparent !important;
      padding-right: 0;
    }

    &.form-control {
      border-right-color: transparent !important;

      &:valid {
        border-right-color: transparent !important;
      }
    }
  }

  :last-child {
    &.input-group-text {
      padding-left: 0;
      border-left-color: transparent !important;
    }

    &.form-control {
      border-left-color: transparent !important;

      &:valid {
        border-left-color: transparent !important;
      }
    }
  }

  // handle input in the middle of the group
  .form-control {
    background-color: var(--rich100);

    &:valid {
      background-color: var(--rich100);

      .bgdark & {
        background-color: var(--dark_bg);
      }
    }

    &:not(:first-child):not(:last-child) {
      border-left-color: transparent !important;
      border-right-color: transparent !important;

      &:valid {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
      }
    }
  }

  .input-group-text {
    background-color: var(--rich100);
  }
}
