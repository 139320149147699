.errors {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;

  h1 {
    color: var(--primary);
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  svg {
    .icon-stroke {
      stroke: var(--primary);
    }
  }

  p {
    max-width: 475px;
  }
}
